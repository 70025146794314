<template>
  <el-select
      :value="selected"
      @input="dispatch"
      :placeholder="plc || $t(`message.type`)"
      filterable
      clearable

  >
    <el-option v-for="item in types" :key="item.id" :label="item.name" :value="item.id"></el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import inventory from '@/utils/mixins/components/inventory';

export default {
  mixins: [inventory],
  props: ['type_id'],
  mounted() {
    if (this.types && this.types.length === 0) {
      this.loadTypes();
    }
  },
  watch: {
    type_id:{
      handler: function (val, oldVal) {
        this.selected = val;
        this.$emit("input", this.selected);
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters({
      types: 'patientHistoryTypes/list'
    }),
  },
  methods: {
    ...mapActions({
      loadTypes: 'patientHistoryTypes/index'
    })
  },
};
</script>
