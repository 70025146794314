import { actions } from './patientHistoryType/actions'
import { getters } from './patientHistoryType/getters'
import { state } from './patientHistoryType/state'
import { mutations } from './patientHistoryType/mutations'

export default {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
