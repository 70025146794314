import {i18n} from "@/utils/i18n";

export default {
    methods: {
        isValidLocationSelection(state, region, city) {
            const hasState = !!state;
            const hasRegion = !!region;
            const hasCity = !!city;

            if (
                (hasState && hasRegion && hasCity) ||
                (!hasState && !hasRegion && !hasCity)
            ) {
                return true;
            } else {
                this.$notify({
                    title: "Ошибка",
                    type: "error",
                    offset: 130,
                    message: i18n.t(`message.fill_address`),
                });
                return false;
            }
        }
    }
}