<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h" style="display: inline-block">
              <span> {{ $t("message.new_order") }}</span>
            </div>
            <div style="display: inline-block">
              <span class="ml-4">
                <el-tag class="mr-2">{{ $t("message.quantity") }}: {{ items.length }}</el-tag>
                <el-tag class="mr-2" type="success">{{ $t("message.total_amount") }}:
                  {{ Math.round(totalAmount) | formatMoney }}</el-tag>
                <el-tag>{{ $t("message.id_number") }}:
                  {{ this.old_order ? this.old_order.id : "" }}</el-tag>
              </span>
            </div>
          </div>
        </el-col>

        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
<!--              <el-button :loading="waitingPrint" :disabled="saveBtn" @click="printinsurance(printModel.id)"-->
<!--                type="success">{{ $t("message.print_insurance") }}</el-button>-->
              <el-button :loading="waitingPrint" :disabled="saveBtn" type="success" @click="printMini(printModel.id)">{{
                $t("message.print") }}</el-button>
<!--              <el-button :loading="waitingPrint" :disabled="saveBtn" @click="printDouble(printModel.id)">{{-->
<!--                $t("message.print_double") }}</el-button>-->
              <el-button :loading="waitingPrint" :disabled="saveBtn" @click="print(printModel.id)">{{
                $t("message.print") }}</el-button>
              <el-button type="danger" @click="resetForm()">{{
                  $t("message.reset") }}</el-button>
              <el-button v-if="!old_save" :loading="waiting" @click="submit(false)"
                type="primary">{{ $t("message.save") }}
              </el-button>
              <el-button v-if="old_save" :loading="waiting" @click="updatingOrder(false)" type="primary">{{
                $t("message.update") }}
              </el-button>
<!--              <el-button :loading="waiting" :disabled="saveBtn" @click="resetForm()" type="success">-->
<!--                {{ $t("message.create_new_patient") }}-->
<!--              </el-button>-->
              <el-button :loading="waiting" @click="closeForm()">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-row :gutter="20">
      <el-col :span="16">
        <div class="p-4 border-r-gray">
          <el-row>
            <el-col :sm="8" :md="8" :lg="8" :xl="8">
              <div class="grid-content">
                <div class="add_patient relative p-0">

                  <div v-click-outside="closeModal">
                    <el-form ref="form" class="from_x">
                      <div class="col-label">
                        <el-form-item :label="$t(`message.patient_data`)" prop="searchP">
                          <el-input v-model="search" @input="searchPatients" clearable
                            @focus="isActiveModal = true"></el-input>
                        </el-form-item>
                      </div>
                    </el-form>

                    <div class="absolute-modal" v-if="(yes_user || patients.length) && isActiveModal">
                      <div class="old_patient mb-2" v-if="yes_user">
                        <div class="info_old_patient action" :class="{ active: infoOldSelected }">
                          <a class="cursor-pointer">
                            <span>{{ user.first_name }} {{ user.surname }}
                              {{ user.patronymic }}</span>
                            <span v-if="user.organization" class="tel_infos">
                              <i class="flaticon-call mr-1"></i> {{ user.phone }}
                              <i class="flaticon-home mr-1 ml-2"></i>
                              {{ user.organization.company_name }}</span>
                            <span v-else class="tel_infos">
                              <i class="flaticon-call mr-1"></i>
                              {{ user.phone }}</span>
                          </a>
                        </div>
                      </div>
                      <template v-else>
                        <div class="old_patient" v-for="patient in patients" :key="patient.id">
                          <div class="info_old_patient" :class="{ active: infoOldSelected }">
                            <a class="mb-2" @click="edit(patient);addClass();">
                              <span>
                                {{ patient.surname }} {{ patient.first_name }}
                                {{ patient.patronymic }}
                                <small> {{ patient.id }}</small></span>
                              <span v-if="patient.organization" class="tel_infos">
                                <i class="flaticon-call mr-1"></i>
                                {{ patient.phone }}
                                <i class="flaticon-home mr-1 ml-2"></i>
                                {{ patient.organization.company_name }}</span>
                              <span v-else class="tel_infos">
                                <i class="flaticon-call mr-1"></i>
                                {{ patient.phone }}</span>
                            </a>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>

          <!-- Регистрация -->
          <CrmCreate :patient.sync="patient"/>

          <!-- Таблица -->
          <NewOrderTable :items.sync="items" />
        </div>
      </el-col>

      <el-col :span="8">
        <CreatePatientServiceUnpacked :ref="serviceDrawer.create.component" :driver="drawerPatientService"
          :doctor_id="doctor_id" @items="itemsChanged" @owner_branch_id="getOwnerBranch">
        </CreatePatientServiceUnpacked>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import order from "@/utils/mixins/order";
import patient from "@/utils/mixins/patient";
import users from "@/components/selects/user_list";
import CrmShow from "./crm-show";
import partnerDoctors from "@/components/inventory-select/partner-doctor";
import drawer from "@/utils/mixins/drawer";
import CrmPriceInput from "@/components/crm/include/crm-price-input";
import CrmCreate from "@/views/patient/components/crm-create-2.vue";
import CreatePatientServiceUnpacked from "@/views/scheduler/create-patient-service-unpacked.vue";
import NewOrderTable from "./newOrderTable.vue";

export default {
  mixins: [order, drawer, patient],
  props: ["currentPatient"],
  components: {
    CrmShow,
    users,
    partnerDoctors,
    CrmPriceInput,
    NewOrderTable,
    CreatePatientServiceUnpacked,
    CrmCreate
  },
  data() {
    return {
      RoomName: "",
      RoomNumber: "",
      isActiveModal: false,
      old_save: false,
      specialty_id: null,
      old_order: {},
      infoOldSelected: false,
      drawerShow: false,
      reopenShow: false,
      saveBtn: true,
      printModel: {},
      owner_branch_id: null,
      express_check: false,
      reopenUpdate: false,
      reloadList: false,
      room: null,
      doctor_id: null,
      drawerDepartment: false,
      attributes: {},
      searchServices: "",
      patient: null,
      defaultProps: {
        children: "children",
        label: "label",
        price: "price",
      },
      serviceDrawer: {
        create: {
          name: "create",
          status: false,
          component: "componentDrawerCreate",
        },
      },
    };
  },
  watch: {
    searchServices(val) {
      this.$refs.items.filter(val);
    },
    search() {
      this.infoOldSelected = false;
    },
    open: {
      handler: function (newVal, oldVal) {
        if (newVal === true) {
          this.setNewOrderData();
        }
      },
      deep: true,
      immediate: true,
    },
    conditions: {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.form.condition_id = this.conditions[0].id;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      orderColumns: "orderColumn/list",
      insuranceCompanies: "insuranceCompany/list",
      contractServiceList: "contracts/contract_services",
      // model: "orders/relation_model",
      specialties: "specialties/list",
      workTimes: "workTimes/inventory",
      rules: "orders/rules",
      express: "expresses/list",
    }),
  },
  mounted() {
    if (this.workTimes && this.workTimes.length === 0) this.setworkTimes();
    if (this.conditions && this.conditions.length === 0) this.loadConditions();
    const order_column_query = {
      get_actives: true,
      place: "is_register",
      per_page: 1000000,
    };
    this.getOrderColumns(order_column_query);
    this.getInsuranceCompany();
    this.getSpecialties();
    if (this.express && this.express.length === 0) this.loadExpress();
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions({
      getInsuranceCompany: "insuranceCompany/index",
      getOrderColumns: "orderColumn/index",
      updateSetlist: "services/updateSetlist",
      setworkTimes: "workTimes/inventory",
      loadExpress: "expresses/index",
      editService: "services/show",
      getSpecialties: "specialties/index",
      servicesRealtionList: "services/relationList",
      contractServices: "contracts/contractServices",
      discountPatient: "discountPatients/show",
      // empty_conclusions: "conclusions/empty",
      showModel: "conclusions/show",
      save: "orders/store",
      update: "orders/update",
      saveList: "services/setlist",
      emptyPatient: "patients/empty",
    }),
    getOwnerBranch(val) {
      this.owner_branch_id = val;
    },
    filterDepartment(val) {
      const query = { partner_clinic_id: val };
      this.loadDepartments(query).then((res) => {
        if (!res.data.departments.data.length) this.form.department_id = null;
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      let dd = data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      return dd;
    },
    selectDoctorService(val) {
      let item = this.$refs.items.getCheckedNodes();
      let arr = [];
      this.emptyList();
      for (const i in item) {
        if (item[i]) {
          const element = item[i];
          if (element.children.length == 0) {
            arr.push(element);
          }
        }
      }
      this.updateSetlist(JSON.parse(JSON.stringify(arr))).then((res) => {
        this.items = JSON.parse(JSON.stringify(this.list));
      });
    },
    addClass() {
      this.infoOldSelected = true;
    },
    parentEvent(value) {
      this.servicesFreeList({
        selected_relation: true,
        only_child: true,
        doctor_id: value,
      });
      this.emptyList();
      // this.items = [];
      this.doctor_id = value;
    },
    async show(model) {
      this.$loadingCursor("wait");
      await this.showModel(model.id)
        .then((res) => {
          this.$loadingCursor("default");
          this.drawerShow = true;
        })
        .catch((err) => {
          this.$loadingCursor("default");
        });
    },
    disableDiscount() {
      if (
        this.discountPatients.length &&
        this.discountPatients[0].discount != 0 &&
        this.discountPatients[0].status
      ) {
        return true;
      } else return false;
    },

    expressPrice(is_express) {
      if (is_express) {
        return 1 + this.express[0].percent / 100;
      } else {
        return 1;
      }
    },
    discountService(sevice_discount) {
      if (
        this.discountPatients.length &&
        this.discountPatients[0].discount != 0 &&
        this.discountPatients[0].status
      ) {
        this.items.forEach((element) => {
          element.discount_price = this.discountPatients[0].discount;
        });
        return this.discountPatients[0].discount;
      } else return sevice_discount;
    },
    itemsChanged(val) {
      this.items = JSON.parse(JSON.stringify(val));
    },
    fetchData() {
      this.loadingData = true;
      const query = { relation: true };
      this.servicesRealtionList(query)
        .then((res) => {
          this.loadingData = false;
        })
        .catch((err) => { });
    },
    setNewOrderData() {
      this.form = JSON.parse(JSON.stringify(this.model));
      this.form.day = new Date();
    },
    closeModal() {
      this.isActiveModal = false
    },
    submit(close) {
      this.changeWaiting(true);

      if (this.user !== null) {
        this.form.patient_id = this.user ? this.user.id : null;
        this.form.patient = this.user;
      } else if (this.patient !== null) {
        this.form.patient = this.patient;
        if (
          !this.isValidLocationSelection(
            this.form.patient.state_id,
            this.form.patient.region_id,
            this.form.patient.city_id
          )
        ) {
          this.changeWaiting();
          return;
        }
      }

      this.form.services = this.items;
      this.form.owner_branch_id = this.owner_branch_id;
      this.form.order_attributes = this.attributes;
      this.save(this.form)
        .then((res) => {
          this.old_save = true;
          this.old_order = res.data.order;
          this.form.patient_id = this.form.patient.id = res.data.order.patient_id;
          this.changeWaiting();
          this.saveBtn = false;
          this.printModel = res.data ? res.data.order : null;
          this.$notify({
            title: "Успешно",
            type: "success",
            offset: 130,
            message: res.message,
          });
        })
        .catch((err) => {
          this.changeWaiting();
          this.ifValidationError(err);
        });
    },
    updatingOrder(close) {
      this.changeWaiting(true);
      this.form.id = this.old_order.id;
      this.form.services = this.items;

      this.update(this.form)
        .then((res) => {
          this.changeWaiting();
          this.saveBtn = false;
          this.printModel = res.data ? res.data.order : null;
          this.$notify({
            title: "Успешно",
            type: "success",
            offset: 130,
            message: res.message,
          });
        })
        .catch((err) => {
          this.changeWaiting();
          this.ifValidationError(err);
        });
    },
    tabEvent(event) {
      if (
        event.name == "second" &&
        this.form.contract_id &&
        this.contractServiceList.length == 0
      ) {
        this.contractServices({
          id: this.form.contract_id,
          contract_service: true,
        })
          .then((res) => { })
          .catch((err) => { });
      }
    },
    AddContactServices() {
      this.saveList(this.contractServiceList.services)
        .then((res) => { })
        .catch((err) => { });
    },
    closeForm() {
      this.old_order = {};
      this.old_save = false;
      this.empty();
      this.$emit("c-close", { reload: false, drawer: this.drawer });
    },
    resetForm() {
      this.setNewOrderData();
      this.user = {};
      this.noUser();
      this.emptySearch();
      this.emptyList();
      this.old_order = {};
      this.old_save = false;
      this.search = "";
      this.saveBtn = true;
      this.latestServices.order_services = [];
      this.discountPatients = [];
      this.items = [];
      this.empty();
    },
    empty() {
      this.activeTab = "first";
      this.emptyList();
      this.emptySearch();
      this.emptyPatient();
      this.noUser();
      this.user = null;
      this.patient = null;
      this.patientServiceId = "";
      this.search = "";
      this.saveBtn = true;
      this.latestServices.order_services = [];
      this.discountPatients = [];
      this.items = [];
    },
    listChanged() {
      this.reloadList = true;
    },
    parent() {
      return this.$parent.$parent;
    },
    emptyModel() {
      this.empty_conclusions();
    },
  },
};
</script>
<style lang="scss">
.tableKa .el-table_1_column_7 .cell {
  display: block !important;
}

.bemor_uslugalar {
  text-decoration: none;
  list-style: none;
  margin: 0px;
  padding: 0px;

  li {
    border-bottom: 1px solid #dcdfe6;
    line-height: 19px;
    padding: 5px 0;
  }

  div {
    font-weight: 600;
  }

  span {
    text-align: right;
    width: 100%;
    display: block;
  }

  .col__red span {
    color: red;
  }
}

.ow_hiddin {
  height: 50vh;
  overflow-y: scroll;
}

.ow_hiddin::-webkit-scrollbar {
  width: 8px;
}

.ow_hiddin::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #fff;
}

.ow_hiddin::-webkit-scrollbar-thumb {
  background-color: #1f75a8;
  border-radius: 20px;
}

.my_add_pats {
  margin: 10px 0 20px 0;
}

.border-r-gray {
  border-right: 1px solid #dcdfe6;
}

.new_room {
  display: flex;
  width: 100%;
  align-items: center;
  height: 50px;
  /* background: #dcdfe6; */
  border-bottom: 1px solid #dcdfe6;
}

.room_number_title {
  margin-left: 10px;
  font-weight: 600;
}

.my-100 {
  .el-select {
    width: 100%;
  }

  .add_pats {
    margin-top: 0px;
  }
}
.absolute-modal {
  z-index: 200 !important;
}
</style>