import request from '../utils/request'


export function index(params) {
    return request({
        url: '/patientHistoryType',
        method: 'get',
        params
    })
}


export function show(id) {
    return request({
        url: `/patientHistoryType/${id}`,
        method: 'get'
    })
}

export function store(data) {
    return request({
        url: '/patientHistoryType',
        method: 'post',
        data
    })
}


export function update(data) {
    return request({
        url: `/patientHistoryType/${data.id}`,
        method: 'put',
        data
    })
}

export function destroy(id) {
    return request({
        url: `/patientHistoryType/${id}`,
        method: 'delete',
    })
}
