export const uz_messages = {
  Dashboard: "Boshqaruv paneli",
  accaunt: "Hisob",
  access_right: "Kirish huquqi",
  access_rights: "Kirish huquqlari",
  actions: "Harakatlar",
  active: "Faol",
  active_patients: "Faol bemorlar",
  add: "Qo‘shish",
  add_branch: "Filial qo‘shish",
  add_bunk: "Yotoq qo‘shish",
  add_class: "Sinf qo‘shish",
  add_conditions: "Shartlarni qo‘shish",
  add_corps: "Korpus qo‘shish",
  add_department_user: "Bo‘lim foydalanuvchisini qo‘shish",
  add_departmentes: "Bo‘lim qo‘shish",
  add_document: "Hujjat qo‘shish",
  add_drug_indicator: "Dori belgilashni qo‘shish",
  add_drug_indicator_warehouse: "Dori belgilashni qo‘shish (ombor)",
  add_floor: "Qavat qo‘shish",
  add_hospital: "Shifoxona qo‘shish",
  add_hospitalization_type: "Yotqizish turini qo‘shish",
  add_medicine: "Dori qo‘shish",
  add_movement: "Harakat qo‘shish",
  add_nationality: "Millatni qo‘shish",
  add_new_bunk: "Yangi yotoq qo‘shish",
  add_new_department: "Yangi bo‘lim qo‘shish",
  add_new_doctor_sign: "Yangi imzo qo‘shish",
  add_new_partner_clinic: "Yangi hamkor klinika qo‘shish",
  add_new_partner_doctor: "Yangi hamkor doktor qo‘shish",
  add_new_patient: "Yangi bemor qo‘shish",
  add_new_service: "Yangi xizmat qo‘shish",
  add_order_payment: "To‘lov buyurtmasini qo‘shish",
  add_partner_clinic: "Hamkor klinikani qo‘shish",
  add_partner_doctor: "Hamkor doktori qo‘shish",
  add_patient_indicators: "Bemor ko‘rsatkichlarini qo‘shish",
  add_patient_physiotherapy: "Bemorga fizioterapiya qo‘shish",
  add_physiotherapy: "Fizioterapiya qo‘shish",
  add_processes: "Jarayon qo‘shish",
  add_purpose_of_hospitalization: "Yotqizish maqsadini qo‘shish",
  add_region: "Hudud qo‘shish",
  add_requirement: "Talab qo‘shish",
  add_room: "Xona qo‘shish",
  add_service: "Xizmat qo‘shish",
  add_to_reception: "Qabulga yozish",
  add_to_room: "Xonaga yozish",
  add_transaction: "Tranzaksiyani qo‘shish",
  add_type_of_injury: "Jarohat turini qo‘shish",
  add_unit_of_measure: "O‘lchov birligini qo‘shish",
  address: "Manzil",
  address_patient: "Bemor manzili",
  address_register: "Ro‘yxatga olish manzili",
  adds: "Qo‘shish",
  adminPanel: "Admin panel",
  age: "Yosh",
  agent: "Agent",
  aggrement: "Shartnoma",
  all_conclusions: "Barcha xulosalar",
  all_date: "Sana",
  all_laboratory_conclusions: "Laboratoriya xulosalari",
  all_not_paid_price: "To‘lanmagan umumiy summa",
  all_service: "Barcha xizmatlar",
  all_service_patient: "Bemor uchun barcha xizmatlar",
  all_speciality: "Barcha mutaxassisliklar",
  allergy: "Allergiya",
  ambulatory: "Ambulator",
  ambulatory_payment: "Ambulator to'lov",
  amount: "Miqdor",
  another_services: "Boshqa xizmatlar",
  another_services_income: "Boshqa xizmatlardan daromad",
  archive: "Arxiv",
  arrival_date: "Kelish sanasi",
  ask_about_write_off: "O‘chirish haqida so‘rash?",
  authority: "Beruvchi organ",
  auto_write_off: "Avtomatik o‘chirish",
  available: "Mavjud",
  back: "Orqaga",
  balance: "Balans",
  bank: "Bank",
  bank_account: "Hisob raqami",
  basic_information: "Asosiy ma’lumotlar",
  bed_schedules: "Yotoq jadvali",
  blank_head: "Blank boshligi",
  blok: "Blok",
  blood: "Qon guruhi",
  bonus: "Bonus",
  bonus_amount: "Bonus miqdori",
  bonus_date: "Bonus sanasi",
  bonus_debt: "Qoldiq",
  bonus_mode: "Bonus yaratish rejimi",
  bonus_paid_amount: "To‘langan miqdor",
  bonuses: "Bonuslar",
  booked: "Band qilindi",
  born_place: "Tug‘ilgan joy",
  box_available: "Qutida mavjud",
  box_quantity: "Qutidagi miqdor",
  branch: "Filial",
  branches: "Filiallar",
  bunk: "Yotoq",
  busy: "Band",
  buy_ready_product: "Tayyor mahsulot sotib olish",
  c_sum: "so‘m",
  cabinat_balance: "Mijoz balansi",
  cabinet: "Kabinet",
  cach: "Naqd pul",
  cancel: "Bekor qilish",
  cannot_print: "Chop etib bo‘lmaydi",
  cargo_storage: "Yuk ombori",
  cash: "Maoshlar",
  cash_amount: "Maosh miqdori",
  cashbox: "Kassa",
  cashbox_print: "Kassa chop etish",
  cashier_report: 'Kassir hisoboti',
  category: "Kategoriya",
  category_or_service: "Kategoriya yoki xizmat",
  category_services: "Xizmatlar kategoriyasi",
  category_templates: "Shablonlar toifalari",
  change: "O‘zgartirish",
  change_branch: "Filialni o‘zgartirish",
  change_write_off: "O‘chirishni o‘zgartirish",
  choose_branch: "Filialni tanlang",
  choose_category: "Kategoriyani tanlang",
  choose_owner_branch: "Egasi bo‘lgan filialni tanlang",
  choose_status: "Iltimos, holatni tanlang",
  choose_template: "Shablonni tanlang",
  chose_a_speciality: "Mutaxassislikni tanlang",
  cities: "Shaharlar",
  citizenship: "Fuqarolik",
  city: "Shahar",
  class: "Sinf",
  clear: "Tozalash",
  client: "Mijoz",
  client_name: "Mijoz nomi",
  clients: "Mijozlar",
  clinic: "Klinika",
  clinic_diagnos: "Klinik diagnoz",
  close: "Yopish",
  closed_conclusions: "Tasdiqlangan xulosalar",
  code: "Kod",
  code_id: "Kod ID",
  color: "Rang",
  comment: "Izoh",
  commentary: "Izoh",
  company_name: "Kompaniya nomi",
  complated: "Tugatildi",
  conResourcesText: "Resurslar ostida",
  conclusion_date: "Xulosa sanasi",
  conclusion_template_editor: "Xulosa shablonlarini tahrirlash",
  conclusion_templates: "Hujjat shakllari",
  conclusions: "Xulosalar",
  conclusions_history: "Xulosalar tarixi",
  condition: "Holat",
  condition_patient: "Bemor holati",
  conditions: "Holatlar",
  confirm: "Tasdiqlash",
  confirmed: "Tasdiqlangan",
  consultation_services: "Konsultatsiya xizmatlari",
  consultation_services_income: "Konsultatsiya daromadi",
  contact: "Bog‘lanish uchun shaxs",
  content: "Kontent",
  contract: "Shartnoma",
  contract_parent: "Asosiy shartnoma №",
  contract_parnet: "Asosiy shartnoma №",
  contract_payment: "Shartnoma bo‘yicha to‘lovlar",
  contract_service: "Shartnoma xizmatlari",
  contracts: "Shartnomalar",
  contracts_n: "Shartnoma №",
  contractual_basis: "Shartnoma asosida",
  corps: "Korpus",
  correspondent_account: "Korrespondent hisob",
  cost_amount: "Narx miqdori",
  cost_order: "Buyurtma narxi",
  count: "Soni",
  counterparties: "Kontragentlar",
  countries: "Mamlakatlar",
  country: "Mamlakat",
  country_code: "Mamlakat kodi",
  courier: "Kuryer",
  couriers: "Kuryerlar",
  create: "Yaratish",
  create_citizenship: "Fuqarolik qo‘shish",
  create_city: "Yangi shahar",
  create_conclusion: "Xulosa yaratish",
  create_condition: "Yangi holat",
  create_currency: "Yangi valyuta",
  create_hospitalization: "Yotqizishni qo‘shish",
  create_module: "Modul yaratish",
  create_new_order: "Yangi buyurtma yaratish",
  create_new_patient: "Yangi bemor yaratish",
  create_order_column: "Maydon nomi",
  create_organization: "Yangi tashkilot",
  create_task: "Vazifa yaratish",
  created: "Yaratilgan",
  created_at: "Yaratilgan sana",
  currency: "Valyuta",
  current_price: "Joriy narx",
  daily: "Bugun uchun",
  dailyProcessReport: "Jarayon hisoboti",
  dailyTreatment: "Kunlik ko‘rsatkich",
  dailyTreatment_patient: "Bemorning kunlik ko‘rsatkichlari",
  daily_hospital: "Kunlik shifoxona",
  dapartment_user: "Bo‘lim foydalanuvchilari",
  dark: "Qorong‘u",
  date: "Yaratilgan sana",
  date_0: "Sana",
  date_aggrement: "Shartnoma sanasi",
  date_hospitalization: "Yotqizish sanasi",
  date_injury: "Kasallik sanasi",
  date_movement: "Harakat sanasi",
  date_of_issue: "Berilgan sana",
  date_produced: "Ishlab chiqarilgan sana",
  date_receiving: "Qabul qilingan sana",
  date_register: "Ro‘yxatdan o‘tish sanasi",
  date_requirement: "Talab sanasi",
  day: "Kun",
  day_hospital: "Kunlik shifoxona",
  day_hospital_for_month: "Oy davomida kunlik shifoxona",
  day_hospital_for_today: "Bugungi kunlik shifoxona",
  day_hospital_for_week: "Hafta davomida kunlik shifoxona",
  day_hospital_income: "Kunlik shifoxona daromadi",
  day_in_warehouse: "Omborda o‘tkazilgan kunlar",
  dds: "DDS",
  deal_amount: "Shartnoma miqdori",
  debt_type: "Qarzdorlik turlari",
  debtors: "Qarzdorlar",
  debts: "Qarzdorlik",
  defects: "Nuqsonlar",
  delete: "O‘chirish",
  delete_calcel: "O‘chirish bekor qilindi",
  delete_notification: "Bu faylni butunlay o‘chirib tashlaydi. Davom etilsinmi?",
  delete_notification_confirm: "Uni o‘chirib tashlamoqchimisiz?",
  delete_setting: "O‘chirish",
  delete_success: "O‘chirish yakunlandi",
  delivery_deadline: "Yetkazib berish muddati",
  delivery_time: "Yetkazib berish vaqti",
  department: "Bo‘lim",
  departments: "Filiallar",
  departure_date: "Jo‘nash sanasi",
  description: "Tavsif",
  detail: "Batafsil",
  diagnos: "Dastlabki diagnoz",
  direction: "Yo‘nalish",
  disable: "O‘chirib qo‘yish",
  disabled: "Faol emas",
  discount: "Chegirma",
  discount_cost: "Chegirma narxi",
  discount_percent: "Chegirma foizi",
  discount_price: "Chegirma (so‘m)",
  discounts: "Chegirmalar",
  district: "Tuman",
  do_you_really_want_to_complete_the_hospitalization: "Yotqizishni yakunlashni istaysizmi?",
  do_you_really_want_to_finish: "Yakunlamoqchimisiz?",
  do_you_want: "Ehtiyot bo‘ling, buni qilishni xohlaysizmi?",
  do_you_want_confirm: "Tasdiqlashni xohlaysizmi?",
  dob: "Tug‘ilgan sana",
  doctor: "Shifokor",
  doctorProcessReport: "Jarayonlar bo‘yicha umumiy hisobot",
  doctorSign: "Imzo",
  doctorSigns: "Imzolar",
  doctor_0: "Doktor",
  doctor_bonuses: "Shifokor bonuslari",
  doctor_can: "Shifokoringizga ko‘rining",
  doctor_id: "Doktor ID",
  doctor_name: "Doktor (to‘liq ism)",
  doctor_service: "Doktor xizmatlari",
  doctors_settings: "Doktor sozlamalari",
  documents: "Hujjat",
  domain_name: "Domen nomi",
  domen: "Domen",
  done_treatment: "Bajarildi (dori muvaffaqiyatli kiritildi)",
  dont_have_file: "Fayl yo‘q",
  download: "Yuklab olish",
  drive_license: "Haydovchilik guvohnomasi",
  drug: "Dori",
  drug_bad_reaction: "Nuqsonlar (Doridan keyin reaktsiya kuzatildi)",
  drug_good_reaction: "Davom eting (reaktsiya kuzatilmadi, protsedurani davom eting)",
  drug_indicator: "Dori belgilash",
  drug_indicator_warehouse: "Dori belgilash (ombor)",
  drug_instruction: "Dori / Ko‘rsatmalar",
  duration: "Davomiylik",
  dynamic_photo: "Dinamik fon rasmi",
  edit: "Tahrirlash",
  email: "Elektron pochta",
  email_address: "Elektron pochta manzili",
  employees_work_schedule: "Xodimlarning ish jadvali",
  enable: "Faollashtirish",
  enabled: "Faol",
  endPeriod: "Gacha",
  end_date: "Tugash sanasi",
  end_period: "Muddat oxiri",
  enter_id: "ID kiriting",
  enumeration: "Ro‘yxat",
  excelReport: "Excel hisoboti",
  exemple: "Misol:",
  expence: "Xarajat",
  expences: "Xarajatlar",
  expired: "Muddati tugagan",
  express: "Tezkor",
  expresses: "Tezkor",
  fax: "Faks",
  female: "Ayol",
  file: "Fayl",
  file_upload: "Faylni yuklash",
  file_uploaded: "Fayl yuklandi",
  fill_address: "Uchta asosiy manzil nuqtasini to'ldiring",
  filter: "Filtrlar",
  filter_keyword: "Filtr kalit so‘zi",
  final_conclusion: "Chiqish",
  fio: "F.I.O",
  first_name: "Ism",
  flat: "Kvartira",
  floor: "Qavat",
  flow_types: "Xarajat turlari",
  footer_header: "Alt",
  footer_image: "Alt tasviri",
  footer_text: "Pastki matn",
  for_bussiness: "Yuridik shaxs",
  for_outsource: "Tashqi ishlar uchun",
  for_personal: "Jismoniy shaxs",
  form_template_and_signs: "Shablonlar va imzolar",
  form_template_sign: "Shablon imzosi",
  forms: "Shakllar (ICD-10)",
  forms_mkb: "Shakllar (ICD-10)",
  free: "Bepul",
  from: "Dan",
  from_hours: "Soatdan boshlab",
  from_time: "Boshlanish vaqti",
  from_whom: "Kimdan",
  full_logo: "To‘liq logo",
  full_name: "To‘liq ism",
  gender: "Jinsi",
  gender_patients: "Bemor jinsi",
  geography_settings: "Geografik sozlamalar",
  glass: "Shisha",
  go_back: "Orqaga qaytish",
  group: "Guruh",
  group_of_patient: "Bemor yosh guruhlari",
  groups: "Guruhlar",
  half_logo: "Yarim logo",
  half_yearly: "Yarim yillik",
  height: "Bo‘yi",
  hidden: "Ko‘rsatilmagan",
  history: "Tarix",
  history_transaction: "Tranzaksiya tarixi",
  hospital: "Shifoxona",
  hospital_bonus_price: "Gospitalizatsiya bonusi narxi",
  hospital_income: "Shifoxona daromadi",
  hospital_patients_month: "Shifoxonadagi bemorlar (oy davomida)",
  hospital_patients_today: "Shifoxonadagi bemorlar (bugun)",
  hospital_patients_week: "Shifoxonadagi bemorlar (hafta davomida)",
  hospital_payment: "Statsionar to'lov",
  hospitalization: "Yotqizish",
  hospitalization_bonuses: "Gospitalizatsiya bonuslari",
  hospitalization_complated: "Yotqizish tugatildi",
  hospitalization_type: "Yotqizish turi",
  hour: "Soat",
  house: "Uy",
  how_many_days: "Necha kun",
  how_prepare_medicine: "Dorilarni qanday tayyorlash kerak",
  id_code: "Identifikatsiya kodi",
  id_number: "Buyurtma ID",
  ifo: "F.I.O",
  income_product: "Kelgan mahsulotlar",
  incoming: "Keluvchi",
  indicator: "Ko‘rsatkich",
  indicators: "Ko‘rsatkichlar",
  info_aggrement: "Shartnoma haqida ma’lumot",
  info_treatment: "Davolash haqida ma’lumot",
  inn: "INN",
  input: "Kiritish",
  input_order_id_for_delete_status: "O‘chirish holati",
  instruction_for_use: "Foydalanish bo‘yicha ko‘rsatma",
  insurance_company: "Sug‘urta kompaniyasi",
  insurance_number: "Sug‘urta raqami",
  integration: "Integratsiya",
  integration_price: "Integratsiya narxi",
  invalid: "Yaroqsiz",
  invoice: "Hisob-faktura",
  invoice_insurance: "Sug‘urta hisob-fakturalari",
  invoices: "Hisob-fakturalar",
  is_active: "Faol",
  is_close: "Xulosa tasdiqlandi",
  is_conclusion: "Xulosa",
  is_register: "Ro‘yxat",
  is_report: "To‘liq hisobot",
  lab_nurse: "Namuna olish",
  laborant: "Laborant",
  laboratory: "Laboratoriya",
  lack_of: "Yetishmaydi",
  last_login: "Oxirgi kirish",
  lasting: "Davomiylik",
  legal_address: "Yuridik manzil",
  lend: "Berish",
  letterhead: "Hamkor",
  life_time: "Hayot davomiyligi",
  lifetime: "Hayot davomiyligi",
  lifetime_services: "Umrbod xizmatlar",
  light: "Yorug‘",
  limit: "Limit",
  load: "Yuklash",
  loading: "Yuklanmoqda",
  locked: "Bloklangan",
  log_out: "Chiqish",
  login: "Kirish",
  logo: "Logo",
  main: "Asosiy",
  main_warehouses: "Asosiy omborlar",
  male: "Erkak",
  man: "Erkak",
  manufactured_date: "Ishlab chiqarilgan sana",
  material: "Material",
  materials_consuption: "Materiallar sarfi",
  materials_consuptions: "Materiallar sarflari",
  measurement: "O‘lchov",
  measurement_full: "O‘lchov birligi",
  measurements: "O‘lchovlar",
  medical_history: "Tibbiy tarix",
  medicine: "Mahsulotlar",
  medicines: "Dorilar",
  medicines_on: "Dorilar N/O",
  men: "Erkaklar",
  mfo: "MFO",
  minut: "Daq.",
  modules: "Modullar",
  money_per_day: "Kunlik narx",
  month: "Oy",
  monthly: "Oylik",
  movement: "Harakat",
  my_conclusions: "Mening xulosalarim",
  my_informal_laboratory_patients: "Laboratoriya buyurtmalari",
  my_informal_patients: "Shifokor buyurtmalari",
  my_laboratory: "Mening laboratoriyam",
  my_office: "Mening kabinetim",
  my_patients: "Mening bemorlarim",
  name: "Ism",
  name_0: "Ism",
  name_service: "Xizmat nomi",
  name_template: "Shablon nomi",
  nationality: "Millati",
  nds: "QQS",
  needed: "Kerakli",
  new: "Yangi",
  new_aggrement: "Yangi shartnoma",
  new_category_service: "Yangi xizmat kategoriyasi",
  new_client: "Yangi mijoz",
  new_country: "Yangi mamlakat",
  new_daily_process_report: "Yangi jarayon hisobot",
  new_discount: "Yangi chegirma",
  new_expence: "Yangi xarajat",
  new_groups: "Yangi guruh",
  new_measurements: "Yangi o‘lchov birligi",
  new_order: "Yangi buyurtma",
  new_owner_clinic: "Yangi brendlash",
  new_partner_clinic: "Yangi hamkor klinika",
  new_partner_doctor: "Yangi hamkor doktor",
  new_patient: "Bemor qo‘shish",
  new_payment_type: "Yangi to‘lov turi",
  new_photo: "Rasm qo‘shish",
  new_price: "Bir miqdor uchun narx",
  new_product: "Yangi mahsulot",
  new_project: "Yangi loyiha",
  new_report: "Yangi hisobot",
  new_role: "Yangi rol",
  new_room: "Yangi xona",
  new_service: "Yangi xizmat",
  new_source: "Yangi manba",
  new_specialities: "Yangi mutaxassislik",
  new_status: "Yangi holat",
  new_supplier: "Yangi yetkazib beruvchi",
  new_system: "Yangi tizim",
  new_tag: "Yangi teg",
  new_tariff: "Yangi tarif",
  new_task: "Yangi vazifa",
  new_time: "Yangi vaqt",
  new_type_document: "Yangi hujjat turi",
  new_type_expence: "Yangi xarajat turi",
  new_user: "Yangi foydalanuvchi",
  new_warehouse: "Yangi ombor",
  next: "Keyingi",
  no: "Yo‘q",
  normaCost: "Standart xarajat",
  normaLaboratory: "Laboratoriya standarti",
  not_full: "To‘liq emas",
  not_paid: "To‘lanmagan",
  not_paid_invoices: "To‘lanmagan hisob-fakturalar",
  not_paid_of_all: "Hech narsa to‘lanmagan",
  not_paid_sum: "To‘lanmagan summa",
  not_set: "Belgilanmagan",
  note: "Eslatma",
  notes: "Eslatmalar",
  npt_active: "Faol emas",
  number: "Raqam",
  number_aggrement: "Shartnoma raqami",
  number_card: "Ambulator kartasi raqami",
  number_of_closed_conclusions_month: "Bu oy tasdiqlangan xulosalar",
  number_of_orders_month: "Bu oygi buyurtmalar",
  number_of_orders_today: "Bugungi buyurtmalar",
  number_of_orders_total: "Jami buyurtmalar",
  number_of_patients_month: "Bu oygi bemorlar",
  number_of_patients_today: "Bugungi bemorlar",
  number_of_patients_total: "Jami bemorlar",
  number_of_patients_week: "Bu haftadagi bemorlar",
  number_of_written_conclusions_month: "Bu oy yozilgan xulosalar",
  nurse_office: "Hamshira xonasi",
  nurse_wrong_choice: "Hamshira noto‘g‘ri xujayrani tanladi",
  oked: "OKED",
  okonx: "OKONH",
  old: "Eski",
  on_close: "Yopilayotganda",
  on_payment: "To‘lovda",
  once_price: "Bir dona narxi",
  onlis: "ONLIS",
  open: "Ochish",
  operation_canceled: "Amal bekor qilindi",
  order: "Buyurtma",
  orderServiceTerm: "Sanalar bo‘yicha buyurtmalar",
  order_amount: "Xizmat miqdori",
  order_amput: "Xizmat miqdori",
  order_column: "Maydon yaratish",
  order_count: "Buyurtmalar soni",
  order_created_at: "Buyurtma qabul qilingan sana",
  order_date: "Buyurtma sanasi",
  order_id: "Buyurtma ID",
  order_info: "Buyurtma haqida ma'lumot",
  order_not_found: "Buyurtma topilmadi",
  order_number: "Buyurtma raqami",
  order_payment: "Buyurtma to‘lovi",
  order_service: "Buyurtma xizmati",
  order_updated_at: "Bajarilgan sana",
  orders: "Buyurtmalar",
  organization: "Tashkilot",
  our_doctor: "Bizning doktorimiz",
  outgoing: "Chiqish",
  outpatient_card: "Ambulator kartasi",
  outsource: "Outsource",
  outsource_add: "Outsource qo‘shish",
  outsource_party: "Outsource partiyasi",
  outsourcing_files: "Outsource fayli",
  overpaid: "Haddan tashqari to‘lov",
  overpaid_amount: "Ortiqcha miqdor",
  own_doctor: "Bizning shifokorimiz",
  owner_branch: "Filiallar",
  owner_clinic: "Brendlash",
  package_quantity: "Qutidagi miqdor",
  page_header: "Sahifa sarlavhasi",
  paid: "To‘langan",
  paid_invoices: "To‘langan hisob-fakturalar",
  paid_partner_bonus: "Hamkorlarga to‘langan bonuslar",
  paid_price: "To‘langan miqdor",
  parent: "Ota",
  parent_category: "Ota kategoriya",
  parent_service: "Ota xizmatlar",
  partner_amount: "Hamkor miqdori",
  partner_bonus: "Hamkor bonuslari",
  partner_clinic: "Hamkor klinikasi",
  partner_clinic_full_logo: "To‘liq logo",
  partner_clinic_full_logo_active: "To‘liq logo faol",
  partner_clinic_mini_logo: "Mini logo",
  partner_clinic_mini_logo_active: "Mini logo faol",
  partner_clinics: "Hamkor klinikalari",
  partner_doctor: "Hamkor shifokor",
  partners: "Hamkorlar",
  party_services: "Partiyalar xizmatlari",
  passport_number: "Pasport raqami",
  password: "Parol",
  patient: "Bemor",
  patientResidenceReport: "Hudud bo‘yicha hisobot",
  patientServiceReport: "Xizmatlar bo‘yicha hisobot",
  patient_0: "Bemor",
  patient_age_reports: "Yosh bo‘yicha hisobotlar",
  patient_ages: "Bemor yoshi",
  patient_balance: "Bemor balansi",
  patient_conclusions: "Bemor xulosalari",
  patient_histories: "Bemor tarixi",
  patient_history: "Bemor tarixi",
  patient_hospitalization: "Bemorning yotqizilishi",
  patient_id: "Bemor ID",
  patient_indicator: "Bemor ko‘rsatkichlari",
  patient_indicators: "Bemor ko‘rsatkichlari",
  patient_info: "Bemor haqida ma’lumot",
  patient_name: "Bemor ismi",
  patient_or_fio: "Bemor No. yoki F.I.O",
  patient_physiotherapy: "Bemor fizioterapiyasi",
  patient_reject: "Bemor rad etdi",
  patient_report: "Bemor xulosalari",
  patient_service: "Bemor xizmatlari",
  patients: "Bemorlar",
  patients2: "Bemorlar",
  patients_count: "Bemorlar soni",
  patients_income: "Klinika daromadi",
  patronymic: "Otasining ismi",
  pay: "To‘lash",
  pay_order: "Buyurtmani to‘lash",
  payable_sum: "To‘lanadigan summa",
  payment: "To‘lov",
  paymentBonusP: "Bonus to‘lovlari",
  payment_report_limit: "To‘lov hisoboti limiti",
  payment_reports: "To‘lov hisobotlari",
  payment_type: "To‘lov turi",
  payment_types: "To‘lov turlari",
  payments: "To‘lovlar",
  payments_for_expenses: "Xarajatlar uchun to‘lovlar",
  payments_report: "To‘lovlar bo‘yicha hisobot",
  percent: "Chegirma %",
  percent_: "%",
  percentage: "Foiz",
  period: "Davr",
  phone: "Telefon",
  phone_number: "Telefon raqami",
  phone_number_is_not_valid: "Bu telefon raqami yaroqsiz",
  phone_numbers: "Telefon raqamlari",
  phone_two: "Ikkinchi telefon",
  photo: "Foto (3x4 sm)",
  phy_office: "Fizioterapiya xonasi",
  physiotherapy: "Fizioterapiya",
  pieces: "dona",
  placeholder_time_date: "Sanani va vaqtni tanlang",
  please_choose_parent: "Iltimos, ota-kategoriyani tanlang",
  please_choose_role: "Iltimos, rolni tanlang",
  please_enter_parol: "Iltimos, parolni kiriting",
  pocket: "cho'ntak",
  pockets: "Cho‘ntaklar",
  position: "Lavozim",
  pp: "P.P.",
  preparing: "Tayyorlanmoqda",
  previous: "Oldingi",
  price: "Narx",
  price2: "Narx",
  price_aggrement: "Shartnoma summasi",
  price_one_day_min_room_price: "1 kunlik minimal ranza paketi narxi",
  price_one_day_with_room_price: "1 kunlik xona bilan paket narxi",
  price_one_day_without_room_price: "1 kunlik paket narxi",
  price_per_day: "Kunlik narx",
  price_with_room_price: "{days} kun uchun xona bilan paket narxi",
  print: "Chop etish",
  print_audit: "Audit chop etish",
  print_center: "Chop etish markazi",
  print_conclusion: "Xulosani chop etish",
  print_double: "2x Chop etish",
  print_form: "Chop etish shakli",
  print_hitory: "Chop etish tarixi",
  print_insurance: "Tibbiy karta",
  print_qr: "QR kodni chop etish",
  printing: "Chop etilmoqda",
  procedural: "Protsessual",
  procedural_0: "Protsessual",
  procedural_list: "Protsessual ro‘yxat",
  process: "Jarayon",
  process_l: "L.P.D jarayonlari",
  processes: "Jarayonlar",
  procurement: "Xarid",
  product: "Mahsulot",
  product_detail: "Mahsulot tafsilotlari",
  products: "Mahsulotlar",
  profile: "Profil",
  project: "Loyiha",
  project_name: "Loyiha nomi",
  projects: "Loyihalar",
  provision: "Shart",
  puls: "Bosim",
  purchase_price: "Xarid narxi",
  purchase_type: "Xarid turi",
  purchasing_products: "Mahsulotlar xaridi",
  purpose_of_hospitalization: "Yotqizish maqsadi",
  purpose_quantity: "Maqsad miqdori",
  qty_come: "Kiruvchi miqdor",
  quantity: "Miqdor",
  quantity_short: "Miq.",
  quarantine: "Karantin",
  quarterly: "Har chorak",
  rate: "Kurs",
  raw_producement: "Xomashyo xaridi",
  reason: "Sabab",
  receive: "Kiruvchi",
  receive_: "Qabul qilish",
  reception_visit: "Qabulga tashrif",
  recipient: "Qabul qiluvchi",
  refer_to_hospital: "Kasalxonaga murojaat qiling",
  refresh: "Yangilash",
  refund: "Qaytarish",
  region: "Hudud",
  reject: "Rad qilish",
  rejected: "Rad etilgan",
  remaind: "Qoldiq",
  remainder: "Qoldiq",
  remainder_reports: "Protsessual hisobot",
  remainders: "Qoldiqlar",
  remove: "O'chirish",
  replacement: "Almashtirish",
  report_clinics: "Klinika bo‘yicha hisobot",
  report_doctors: "Shifokorlar bo‘yicha hisobot",
  report_insurance_company: "Sug‘urta kompaniyasi hisobot",
  report_partner_clinics: "Hamkor klinikalar hisoboti",
  report_partner_doctors: "Hamkor shifokorlar hisoboti",
  report_warehouse: "Ombor hisobotlari",
  reports: "Hisobotlar",
  requirement: "Talab",
  requirements: "Talablar",
  reset: "Qayta o‘rnatish",
  resident_price: "Rezidentlar uchun narx",
  resourcesText: "Resurslar",
  responsible_name: "Vakil",
  return_material: "Materialni qaytarish",
  reverse: "Teskari",
  reverse_rate: "Teskari kurs",
  rkp_nds: "RKP QQS",
  role: "Rol",
  role_name: "Rol nomi",
  roles: "Rollar",
  room: "Xona",
  rooms: "Xonalar",
  rooms_number: "Xona raqami",
  running_title: "Joriy sarlavha",
  saldo: "Qoldiq",
  sale_amount: "Sotish miqdori",
  sale_percentage: "Sotish foizi",
  sale_price: "Sotish narxi",
  save: "Saqlash",
  save_and_close: "Saqlash va yopish",
  save_and_create_new: "Saqlash va yangisini qo‘shish",
  save_and_go: "Saqlash va davom etish",
  schedule: "Jadval",
  schedule_cabinet: "Kabinet jadvali",
  score: "Hisoblar",
  scores: "Hisoblar",
  search: "Qidiruv",
  search_0: "Qidiruv",
  search_doctor: "Shifokor qidirish",
  search_order: "Buyurtmalarni topish",
  search_order0: "Buyurtmani topish",
  search_template: "Shablonni topish",
  secondary_doctor: "Yuboruvchi shifokor",
  select_patient: "Bemorni tanlang",
  select_patient_or_find: "Chapdan bemorni tanlang yoki yangisini qo‘shing",
  select_time: "Sanani va vaqtni tanlang",
  send: "Yuborish",
  sent: "Yuborildi",
  serial_number: "Seriya raqami",
  series_and_number: "Seriya va raqam",
  service: "Xizmat",
  service_category: "Xizmat kategoriyasi",
  service_categoy: "Xizmat kategoriyasi",
  service_name: "Xizmat nomi",
  service_type: "Xizmat turi",
  service_type_id: "Xizmat kategoriyasi ID",
  service_type_name: "Xizmat turi nomi",
  service_types: "Xizmat turlari",
  services: "Xizmatlar",
  services_count: "Xizmatlar soni",
  set_header_footer: "Bosh va oxir qismini o‘rnatish",
  set_sign: "Imzoni o‘rnatish",
  set_transaction_amount: "Tranzaksiya summasini belgilang",
  settings: "Sozlamalar",
  short_friday: "J",
  short_monday: "Dsh",
  short_name: "Qisqa nom",
  short_saturday: "Sh",
  short_sunday: "Yak",
  short_thursday: "Psh",
  short_tuesday: "Ssh",
  short_wednesday: "Chsh",
  show: "Ko‘rsatildi",
  show_0: "Ko‘rsatish",
  show_audit: "Auditni ko‘rsatish",
  show_conclusions: "Xulosalarni ko‘rsatish",
  show_drug: "Dorilarni ko‘rish",
  show_valid_price: "Dorilar narxini ko‘rsating",
  showing: "Ko‘rish",
  sickNumber: "ICD kodi",
  sign_in: "Kirish",
  sku: "SKU",
  slug: "Noyob identifikator",
  social: "Manba",
  source: "Manba",
  specialities: "Mutaxassisliklar",
  speciality: "Mutaxassislik",
  speciality_doctor: "Doktor mutaxassisligi",
  startPeriod: "Boshlanish sanasi",
  start_date: "Boshlanish sanasi",
  start_input_find_service: "Xizmat nomini yoki maqolasini kiriting",
  start_period: "Boshlanish",
  stasionary_cashier: "Stasionar kassir",
  static_info: "Statistika",
  stationary: "Statsionar",
  stationary_settings: "Statsionar sozlamalari",
  status: "Holatlar",
  status_0: "Holat",
  status_closed: "Muvaffaqiyatli",
  status_condition: "Holat holati",
  status_doctor: "Doktor bilan",
  status_doctor_free: "Doktor buyurtmalari",
  status_lab_nurse: "Namuna olish",
  status_laborant: "Laborant bilan",
  status_laborant_free: "Laboratoriya buyurtmalari",
  status_nurse: "Namuna olish",
  status_payment: "To‘lovda",
  status_semi_created: "Tasdiq kutmoqda",
  status_waiting_paid: "To‘lovni kutmoqda",
  status_written: "Xulosa yozildi",
  street: "Ko‘cha",
  subject_of_agreement: "Shartnoma mavzusi (xizmatlar)",
  sum: "Summa",
  sum_expence: "Xarajat summasi",
  sum_receive: "Kirish summasi",
  summ: "Summa",
  supplier: "Yetkazib beruvchi",
  suppliers: "Yetkazib beruvchilar",
  suppliers_aggrement: "Yetkazib beruvchilar shartnomalari",
  surname: "Familiya",
  symbol: "Belgilar",
  system_settings: "Tizim sozlamalari",
  systems: "Tizimlar",
  tags: "Teglar",
  tariff: "Tarif",
  tariffs: "Tariflar",
  task_type: "Vazifa turi",
  task_types: "Vazifalar turlari",
  tasks: "Vazifalar",
  tel_number: "Telefon raqami",
  temperature: "Harorat",
  templates: "Shablonlar",
  term: "Muddat",
  term_aggrement: "Shartnoma muddati",
  terminal: "Terminal",
  test: "Test",
  theme_setting: "Mavzu sozlamalari",
  time: "Vaqt",
  times: "Vaqtlar",
  title: "Sarlavha",
  to_history: "Tarixga",
  to_time: "Tugash vaqti",
  today_transactions: "Bugungi umumiy miqdor",
  top_services: "Eng yaxshi xizmatlar",
  total: "Jami",
  total_0: "Jami",
  total_amount: "Umumiy miqdor",
  total_cost: "Umumiy narx",
  total_payment: "To'liq to'lov",
  total_price: "Umumiy summa",
  total_reports: "Jami hisobotlar",
  totals: "Jami",
  totaly: "Umumiy",
  transactionScores: "Balans",
  transactions: "Tranzaksiyalar",
  transmitter: "Jo‘natuvchi",
  type: "Turi",
  type_condition: "Holat turi",
  type_documents: "Hujjat turi",
  type_expence: "Xarajat turi",
  type_expences: "Xarajatlar turlari",
  type_of_injury: "Jarohat turi",
  type_phone: "Telefon turi",
  type_receive: "Keluvchi turlari",
  typebonus: "Bonus turi",
  unnecessary_enclosure: "Keraksiz xulosa",
  until: "Gacha",
  update: "Yangilash",
  update_access_rights: "Kirish huquqlarini yangilash",
  update_branch: "Filialni yangilash",
  update_bunk: "Yotoqni yangilash",
  update_category_service: "Xizmat kategoriyasini yangilash",
  update_citizenship: "Fuqarolikni yangilash",
  update_city: "Shaharni yangilash",
  update_class: "Sinfni yangilash",
  update_client: "Mijozni yangilash",
  update_condition: "Holatni yangilash",
  update_conditions: "Holatlarni yangilash",
  update_corps: "Korpusni yangilash",
  update_country: "Mamlakatni yangilash",
  update_currency: "Valyutani yangilash",
  update_date: "Sanani yangilash",
  update_department_user: "Bo‘lim foydalanuvchisini yangilash",
  update_departmentes: "Bo‘limni yangilash",
  update_discount: "Chegirmalarni yangilash",
  update_doctor_sign: "Imzoni yangilash",
  update_expence: "Xarajatni yangilash",
  update_floor: "Qavatni yangilash",
  update_hospitalization: "Yotqizishni yangilash",
  update_hospitalization_type: "Yotqizish turini yangilash",
  update_module: "Modulni yangilash",
  update_movement: "Harakatni yangilash",
  update_nationality: "Millatni yangilash",
  update_new_patient: "Yangi bemorni yangilash",
  update_organization: "Tashkilotni yangilash",
  update_partner_clinic: "Hamkor klinikani yangilash",
  update_partner_doctor: "Hamkor doktori yangilash",
  update_patient: "Bemorni yangilash",
  update_patient_indicators: "Bemor ko‘rsatkichlarini yangilash",
  update_payment_type: "To‘lov turini yangilash",
  update_product: "Mahsulotni yangilash",
  update_project: "Loyihani yangilash",
  update_purpose_of_hospitalization: "Yotqizish maqsadini yangilash",
  update_region: "Hududni yangilash",
  update_report: "Hisobotni yangilash",
  update_role: "Rolni yangilash",
  update_room: "Xonani yangilash",
  update_service: "Xizmatni yangilash",
  update_setting: "Yangilash",
  update_source: "Manbani yangilash",
  update_specialities: "Mutaxassislikni yangilash",
  update_system: "Tizimni yangilash",
  update_tags: "Tegni yangilash",
  update_tariff: "Tarifni yangilash",
  update_task: "Vazifani yangilash",
  update_teg: "Tegni yangilash",
  update_time: "Vaqtni yangilash",
  update_type_document: "Hujjat turini yangilash",
  update_type_expence: "Xarajat turini yangilash",
  update_type_of_injury: "Jarohat turini yangilash",
  used: "Foydalanilgan",
  used_cassettes: "Foydalanilgan kassetalar",
  used_glasses: "Foydalanilgan stakanlar",
  used_medicine: "Foydalanilgan dori",
  user: "Foydalanuvchi",
  user_amount: "Foydalanuvchilar soni",
  username: "Egasining nomi:",
  users: "Foydalanuvchilar",
  valid: "Dorilar",
  validation: "Xato",
  version: "Versiya",
  vial_quantity: "Shishalar soni",
  view: "Ko‘rish",
  view_0: "Ko‘rish",
  warehouse: "Ombor",
  warehouse_movement: "Ombor harakatlari",
  warehouses: "Omborlar",
  warehouses_0: "Omborlar",
  warning: "Ogohlantirish",
  web_site: "Veb-sayt",
  website_name: "Veb-sayt nomini kiriting",
  week: "Hafta",
  weekly: "Haftalik",
  weight: "Vazn",
  whence: "Qayerdan",
  where_to: "Qayerga",
  without_amount: "Hisobsiz",
  without_calculation: "Shartnoma №",
  woman: "Ayol",
  women: "Ayollar",
  work_schedule: "Ish jadvali",
  write_conclusions: "Xulosa yozish",
  write_of_from: "O‘chirish sanasi bo‘yicha",
  write_off: "O‘chirish",
  write_off_date: "O‘chirish sanasi",
  write_off_items: "O‘chirish uchun mahsulotlar",
  write_off_products: "Mahsulotlarni o‘chirish",
  write_off_quantity: "O‘chirish miqdori",
  write_offs: "O‘chirishlar",
  written_conclusions: "Yozilgan xulosalar",
  year: "Yil",
  yearly: "Yillik",
  yes: "Ha",
  yes_doctor: "Shifokor bilan bog‘laning",
};
